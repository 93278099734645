import React from 'react';
import PropTypes from 'prop-types';

const LdJson = ({ json }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
    />
  );
};

LdJson.propTypes = {
  json: PropTypes.object.isRequired,
};

LdJson.viewModelMeta = 'ignore';

export default LdJson;
