import isRunningOnClient from '@creuna/utils/is-running-on-client';

const getElementPosition = (el, isCentered, parent) => {
  if (!isRunningOnClient || !el) return;
  const parentHeight = parent.offsetHeight || window.innerHeight;
  const elHeight = el.scrollHeight;
  const heightDif = parentHeight - elHeight;
  const parentScrollTop =
    parent === window ? document.documentElement.scrollTop : parent.scrollTop;

  const offset = 20;
  return isCentered
    ? el.getBoundingClientRect().top + parentScrollTop - heightDif / 2
    : el.getBoundingClientRect().top + parentScrollTop - offset;
};

export const scrollToBlock = ({
  el,
  isCentered = false,
  extraOffset = 0,
  parent,
}) => {
  if (!isRunningOnClient || !el) return;

  if (!parent) parent = window;

  parent.scrollTo({
    top: getElementPosition(el, isCentered, parent) - extraOffset,
    behavior: 'smooth',
  });
};

export default {
  scrollToBlock,
};
