import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as Components from '../../app.components';

const ContentArea = ({ blocks, className, place }) => {
  return blocks ? (
    <div
      className={cn('content-area', {
        [className]: className,
      })}
    >
      {blocks.map((block, index) => {
        const Block = Components[block.componentName];
        if (!Block) {
          // eslint-disable-next-line no-console
          console.error(`Components.${block.componentName} is not defined`);
          return null;
        }
        return (
          <div key={block.componentName + index}>
            <Block {...block} place={place} />
          </div>
        );
      })}
    </div>
  ) : null;
};

ContentArea.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  place: PropTypes.string,
};

ContentArea.viewModelMeta = 'ignore';

export default ContentArea;
