import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import FooterColumn from './footer-column';
import Link from 'components/link';

const Footer = ({
  mainLogo,
  secondaryLogo,
  column1,
  column2,
  column3,
  column4,
  copyrightText,
  cookieInfoLink,
}) => {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__top">
          {mainLogo && (
            <Logo
              {...mainLogo}
              className="footer__logo-main image_size_footer-main-logo"
            />
          )}
          {secondaryLogo && (
            <Logo
              {...secondaryLogo}
              className="footer__logo-secondary image_size_footer-secondary-logo"
            />
          )}
        </div>
        <div className="footer__columns">
          {column1 && <FooterColumn {...column1} />}
          {column2 && <FooterColumn {...column2} />}
          {column3 && <FooterColumn {...column3} />}
          {column4 && <FooterColumn {...column4} />}
        </div>
        <div className="footer__bottom">
          {copyrightText && (
            <div className="footer__bottom-item">{copyrightText}</div>
          )}
          {cookieInfoLink && (
            <Link
              {...cookieInfoLink}
              className="footer__bottom-item footer__link"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  mainLogo: PropTypes.exact(Logo.propTypes),
  secondaryLogo: PropTypes.exact(Logo.propTypes),
  column1: PropTypes.exact(FooterColumn.propTypes),
  column2: PropTypes.exact(FooterColumn.propTypes),
  column3: PropTypes.exact(FooterColumn.propTypes),
  column4: PropTypes.exact(FooterColumn.propTypes),
  copyrightText: PropTypes.string,
  cookieInfoLink: PropTypes.exact(Link.propTypes),
};

export default Footer;
