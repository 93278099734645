import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Language from './language';
import Link from 'components/link';
import Icon from 'components/icon';
import useToggle from 'hooks/use-toggle';
import useEscape from 'hooks/use-escape';
import useDependentToggle from 'hooks/use-dependent-toggle';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import messageBus from 'js/message-bus';
import breakpoints from 'js/breakpoints';
import cn from 'classnames';

const KEY_NAMES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
};

const LANGUAGE_SELECTOR_MENU = 'language-selector-menu';

const LanguageSelector = ({ className, lang, languages }) => {
  const { isActive, toggle, activate, deactivate } = useToggle(false);
  const languageSelectorRef = useRef(null);
  const languageSelectorOpenerRef = useRef(null);

  const [focusedIndex, setFocusedIndex] = useState(null);

  useEscape(deactivate);
  useDependentToggle({
    isActive,
    messageTypeToSend: messageBus.messageTypes.mobileLanguageSelectorOpened,
    messageTypeToSubscribe: messageBus.messageTypes.mobileMenuOpened,
    deactivate,
  });

  useDidUpdateEffect(() => {
    if (
      languageSelectorOpenerRef &&
      languageSelectorOpenerRef.current &&
      !isActive
    ) {
      languageSelectorOpenerRef.current.focus();
      setFocusedIndex(null);
    }
  }, [isActive, languageSelectorOpenerRef]);

  useEffect(() => {
    if (languageSelectorRef && languageSelectorRef.current) {
      const selectorOptions = languageSelectorRef.current.querySelectorAll(
        '.language-selector__link'
      );

      if (
        selectorOptions &&
        selectorOptions.length > 0 &&
        selectorOptions[focusedIndex]
      ) {
        selectorOptions[focusedIndex].focus();
      }
    }
  }, [focusedIndex, languageSelectorRef]);

  const handleKeyDown = event => {
    switch (event.key) {
      case KEY_NAMES.ARROW_DOWN:
        event.preventDefault();
        setFocusedIndex(prevIndex => {
          if (prevIndex === languages.length - 1) {
            return 0;
          }

          return prevIndex === null ? 0 : prevIndex + 1;
        });
        break;
      case KEY_NAMES.ARROW_UP:
        event.preventDefault();
        setFocusedIndex(prevIndex => {
          return prevIndex === 0 ? languages.length - 1 : prevIndex - 1;
        });
        break;
      default:
        break;
    }
  };

  const handleMouseEnter = () => {
    if (breakpoints.desktop()) {
      activate();
    }
  };

  const handleMouseLeave = () => {
    if (breakpoints.desktop()) {
      deactivate();
    }
  };

  return (
    <div
      className={cn('language-selector', {
        [className]: className,
      })}
      ref={languageSelectorRef}
      onKeyDown={handleKeyDown}
    >
      <button
        className="language-selector__opener"
        onClick={toggle}
        ref={languageSelectorOpenerRef}
        aria-expanded={isActive}
        aria-haspopup="true"
        aria-controls={LANGUAGE_SELECTOR_MENU}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon
          name={isActive ? 'close' : 'language'}
          className="language-selector__opener-icon"
        />
        <span
          className="language-selector__opener-text"
          id="language-selector-label"
        >
          {lang.languagesLabel}
        </span>
      </button>
      {languages && languages.length > 0 && (
        <ul
          className={cn('language-selector__list', {
            'language-selector__list_active': isActive,
          })}
          id={LANGUAGE_SELECTOR_MENU}
          aria-labelledby="language-selector-label"
        >
          {languages.map((item, index) => (
            <li
              key={index}
              className={cn('language-selector__item', {
                'language-selector__item_current': item.isCurrent,
              })}
              lang={item.name}
            >
              {item.url ? (
                <Link
                  url={item.url}
                  text={item.displayName}
                  className="language-selector__link"
                ></Link>
              ) : (
                item.displayName
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

LanguageSelector.viewModelMeta = {
  className: 'ignore',
};

LanguageSelector.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.shape({
    languagesLabel: PropTypes.string.isRequired,
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.exact(Language.propTypes)),
};

export default LanguageSelector;
