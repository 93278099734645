import React from 'react';
import PoorText from 'components/poor-text/poor-text';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Footnote = ({ footnoteKey, isActive, index, textHtml }) => {
  return (
    <div
      className={cn('footnote', {
        footnote_active: isActive,
      })}
      id={footnoteKey}
    >
      {index !== undefined && (
        <span className="footnote__index">{index + 1}</span>
      )}
      <span className="footnote__key">{footnoteKey}</span>
      <PoorText
        text={textHtml}
        className={cn('poor-text_in-footnote', {
          'poor-text_in-footnote-active': isActive,
        })}
      />
    </div>
  );
};

Footnote.viewModelMeta = {
  index: 'ignore',
  isActive: 'ignore',
};

Footnote.propTypes = {
  footnoteKey: PropTypes.string.isRequired,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  textHtml: PropTypes.string.isRequired,
};

export default Footnote;
