import PropTypes from 'prop-types';

const ReactComponent = {};

// Empty propTypes needed in order for ReactComponent.cs to be generated.
ReactComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
};

ReactComponent.viewModelMeta = 'ignore'; // Not generated automatically, defained on BE instead.

// 'ReactComponent' is used as the base class in all generated .cs-files (configurerd in CSharpWebpackPlugin options in webpack.config.js)
export default ReactComponent;
