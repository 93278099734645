import React from 'react';
import PropTypes from 'prop-types';

const SkipToMain = ({ text }) => (
  <a className="skip-to-main" href="#main-content">
    {text}
  </a>
);

SkipToMain.propTypes = {
  text: PropTypes.string,
};

export default SkipToMain;
