import { useEffect } from 'react';
import messageBus from 'js/message-bus';

export default function useDependentToggle({
  isActive,
  messageTypeToSend,
  messageTypeToSubscribe,
  deactivate,
}) {
  useEffect(() => {
    if (isActive) {
      messageBus.sendMessage(messageTypeToSend);
    }
  }, [isActive]);

  useEffect(() => {
    messageBus.onMessage(messageTypeToSubscribe, deactivate);
  }, []);
}
