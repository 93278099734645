import PropTypes from 'prop-types';
import Link from 'components/link';

const MenuLink = {};

MenuLink.propTypes = Object.assign({}, Link.propTypes, {
  isActive: PropTypes.bool,
});

export default MenuLink;
