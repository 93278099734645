import React, { useEffect, useRef } from 'react';
import PlayButton from 'components/play-button';
import PropTypes from 'prop-types';

const BackgroundVideo = ({
  url,
  lang = {
    playVideoLabel: 'Play',
    pauseVideoLabel: 'Pause',
    playVideoAriaLabel: 'Play a video',
    pauseVideoAriaLabel: 'Pause a video',
  },
}) => {
  const [videoPlaying, setVideoPlaying] = React.useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (videoPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoPlaying, videoRef]);

  const toggleVideoState = () => {
    setVideoPlaying(prev => !prev);
  };

  return (
    <div className="background-video">
      <video
        src={url}
        ref={videoRef}
        className="background-video__video"
        loop
        autoPlay
        muted
        playsInline
      />
      {lang && (
        <div className="background-video__content">
          <PlayButton
            text={videoPlaying ? lang.pauseVideoLabel : lang.playVideoLabel}
            iconName={videoPlaying ? 'pause-squared' : 'play-squared'}
            ariaLabel={
              videoPlaying ? lang.pauseVideoAriaLabel : lang.playVideoAriaLabel
            }
            className="background-video__play-btn"
            onClick={toggleVideoState}
          />
        </div>
      )}
    </div>
  );
};

BackgroundVideo.propTypes = {
  url: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    playVideoLabel: PropTypes.string.isRequired,
    pauseVideoLabel: PropTypes.string.isRequired,
    playVideoAriaLabel: PropTypes.string.isRequired,
    pauseVideoAriaLabel: PropTypes.string.isRequired,
  }),
};

export default BackgroundVideo;
