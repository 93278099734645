import React from 'react';
import ReactComponent from 'components/react-component';
import PropTypes from 'prop-types';
import * as ItemBlocks from './item-blocks';
import cn from 'classnames';

const topicGroupBlocks = {
  ctaImageBlock: 'CtaImageBlock',
  fullWidthBannerBlock: 'FullWidthBannerBlock',
};

const ccn = (x = '') => 'topic-group-block' + x;

const TopicGroupBlock = ({ heading, category, blocks }) => {
  const allBlocks = [...blocks];
  const topicGroupRows = [];
  while (allBlocks.length) {
    const blocksSlice = [];
    if (allBlocks[0]) {
      blocksSlice.push(allBlocks[0]);
    }
    if (allBlocks[1]) {
      blocksSlice.push(allBlocks[1]);
    }
    const hasFullWidthBlock = blocksSlice.some(
      block => block.componentName === topicGroupBlocks.fullWidthBannerBlock
    );
    topicGroupRows.push(allBlocks.splice(0, hasFullWidthBlock ? 1 : 2));
  }

  return (
    <div
      className={`${ccn()} block`}
      {...(category && { 'data-category': category })}
    >
      <div className={ccn('__container')}>
        {category && (
          <span className={ccn('__category-label')}>{category}</span>
        )}
        {heading && <h2 className={ccn('__heading')}>{heading}</h2>}
        <div className={ccn('__grid')}>
          {topicGroupRows.map((rowItems, rowIndex) => (
            <div className={ccn('__row')} key={rowIndex}>
              {rowItems &&
                rowItems.map((item, itemIndex) => {
                  const ItemBlock = ItemBlocks[item.componentName];
                  const isFullWidth = rowItems.length === 1;
                  if (!ItemBlock) {
                    // eslint-disable-next-line no-console
                    console.error(
                      `Components.${item.componentName} is not supported within TopicGroupBlock`
                    );
                    return null;
                  }

                  return (
                    <div
                      className={cn(ccn('__item'), {
                        [ccn('__item_full-width')]: isFullWidth,
                      })}
                      key={itemIndex}
                    >
                      <ItemBlock {...item} isFullWidth={isFullWidth} />
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TopicGroupBlock.propTypes = {
  heading: PropTypes.string,
  category: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.shape(ReactComponent.propTypes))
    .isRequired,
};

export default TopicGroupBlock;
