import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CircleIcon from 'components/circle-icon';
import FilterTab from './filter-tab';
import messageBus from 'js/message-bus';

const TabsFilter = ({
  tabs,
  selectedCategory,
  defaultCategoryAlwaysEnabled = true,
  defaultCategory,
}) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const getSelectedCategories = () => {
    const categories = [];
    const addDefaultCategory = !activeCategory || defaultCategoryAlwaysEnabled;
    if (activeCategory) {
      categories.push(activeCategory);
    }
    if (defaultCategory && addDefaultCategory) {
      categories.push(defaultCategory);
    }
    return categories;
  };

  useEffect(() => {
    messageBus.sendMessage(messageBus.messageTypes.categoryFilterUpdated, {
      categories: getSelectedCategories(),
    });
    messageBus.onMessage(
      messageBus.messageTypes.currentCategoryFilterRequest,
      m => m.callback({ categories: getSelectedCategories() })
    );
  }, [activeCategory]);

  const updateActiveCategory = category => {
    setActiveCategory(category ? category : null);
  };

  const onTabItemClick = (e, category) => {
    e.preventDefault();

    if (activeCategory === category) {
      history.pushState(null, null, ' ');
      window.dispatchEvent(new HashChangeEvent('hashchange'));
    } else {
      window.location.hash = category;
    }
  };

  const onHashChange = useCallback(() => {
    const hash = window.location.hash.substring(1);

    if (tabs.some(item => item.category === hash) || !hash) {
      updateActiveCategory(window.location.hash.substring(1));
    }
  });

  useEffect(() => {
    const hash = window.location.hash;

    setTimeout(() => {
      if (hash) {
        onHashChange();
      } else {
        updateActiveCategory(selectedCategory);
      }
    }, 0);

    window.addEventListener('hashchange', onHashChange, false);

    return () => window.removeEventListener('hashchange', onHashChange, false);
  }, []);

  return !tabs || tabs.length <= 0 ? null : (
    <div className="tabs-filter">
      <ul className="tabs-filter__tabs">
        {tabs.map((item, index) => {
          const isActive = item.category === activeCategory;

          return (
            <li key={index} className="tabs-filter__tab">
              <button
                className={cn('tabs-filter__tab-link', {
                  'tabs-filter__tab-link_active': isActive,
                })}
                onClick={e => onTabItemClick(e, item.category)}
              >
                {item.icon && (
                  <CircleIcon
                    name={item.icon}
                    isActive={isActive}
                    className="tabs-filter__tab-icon"
                  />
                )}
                <span className="tabs-filter__tab-heading">{item.name}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TabsFilter.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.exact(FilterTab.propTypes)).isRequired,
  selectedCategory: PropTypes.string,
  defaultCategoryAlwaysEnabled: PropTypes.bool,
  defaultCategory: PropTypes.string,
};

export default TabsFilter;
