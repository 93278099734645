import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// NOTE: Rendered on BE side as static html.
const PageHeading = ({ text, className }) => {
  return <h1 className={cn('page-heading', className)}>{text}</h1>;
};

PageHeading.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

PageHeading.viewModelMeta = 'ignore';

export default PageHeading;
