import React from 'react';
import PropTypes from 'prop-types';
import Ldjson from 'components/ldJson';

const ArticleSchema = ({
  headline,
  imageUrls,
  datePublished,
  dateModified,
  authorName,
}) => {
  const schemaJson = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: headline,
    image: imageUrls,
    datePublished: datePublished,
    dateModified: dateModified,
    author: authorName
      ? [
          {
            '@type': 'Organization',
            name: authorName,
          },
        ]
      : undefined,
  };

  return <Ldjson json={schemaJson} />;
};

ArticleSchema.propTypes = {
  headline: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  datePublished: PropTypes.string.isRequired,
  dateModified: PropTypes.string.isRequired,
  authorName: PropTypes.string,
};

export default ArticleSchema;
