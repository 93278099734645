import React from 'react';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PlayButton = ({ iconName, className, text, ariaLabel, onClick }) => {
  return (
    <button
      className={cn('play-button', className)}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {iconName && <Icon name={iconName} className="play-button__icon" />}
      {text && <span className="play-button__text">{text}</span>}
    </button>
  );
};

PlayButton.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

PlayButton.viewModelMeta = 'ignore';

export default PlayButton;
