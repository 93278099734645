import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Ldjson from 'components/ldJson';

const BreadcrumbsSchema = ({ links }) => {
  const schemaJson = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: links.map((link, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: link.text,
      item: link.url,
    })),
  };

  return <Ldjson json={schemaJson} />;
};

BreadcrumbsSchema.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
};

BreadcrumbsSchema.viewModelMeta = 'ignore';

export default BreadcrumbsSchema;
