import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Components from '../../app.components';
import FilteredListItem from './filtered-list-item';
import messageBus from 'js/message-bus';

const FilteredList = ({ items, categories = [] }) => {
  const [currentCategories, setCurrentCategories] = useState(categories);

  useEffect(() => {
    messageBus.onMessage(messageBus.messageTypes.categoryFilterUpdated, m =>
      setCurrentCategories(m.categories)
    );
    messageBus.sendMessage(
      messageBus.messageTypes.currentCategoryFilterRequest,
      { callback: e => setCurrentCategories(e.categories) }
    );
  }, []);

  const itemsWithIndexes = items.map((item, index) => ({ ...item, index }));

  const filteredItems =
    currentCategories && currentCategories.length > 0
      ? itemsWithIndexes.filter(item =>
          currentCategories.includes(item.category)
        )
      : itemsWithIndexes;

  return filteredItems && filteredItems.length > 0 ? (
    <div aria-atomic="true" aria-live="assertive">
      {filteredItems.map(item => {
        const Block = Components[item.component.componentName];
        if (!Block) {
          // eslint-disable-next-line no-console
          console.error(
            `Components.${item.component.componentName} is not defined`
          );
          return null;
        }
        return (
          <Fragment key={item.component.componentName + item.index}>
            <Block {...item.component} category={item.category} />
          </Fragment>
        );
      })}
    </div>
  ) : null;
};

FilteredList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(FilteredListItem.propTypes))
    .isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
};

export default FilteredList;
