import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'components/image';

const Logo = ({ className, url, image, imageClass }) => {
  const RootElement = url ? 'a' : 'div';

  const rootElementProps = {
    ...(url && {
      href: url,
    }),
    className: cn('logo', className),
  };

  return (
    <RootElement {...rootElementProps}>
      {image && <Image {...image} className={cn('logo__image', imageClass)} />}
    </RootElement>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  imageClass: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
};

Logo.viewModelMeta = {
  className: 'ignore',
  imageClass: 'ignore',
};

export default Logo;
